<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="aligncenter wp-image-22859" src="@/assets/news/3/1.jpg" alt="" width="714" height="476" srcset="" sizes="(max-width: 714px) 100vw, 714px"></p>
        <p>马耳他项目作为欧洲老牌黄金签证项目之一，多年来颇受高净值人群青睐，去年，该项目即将迎来重大变动的消息不胫而走，此后便一直牵动人心。在今年1月12日，马耳他政府在授权代理机构会议上透露了永居项目新政，名称是马耳他永居项目（Malta Permanent Residence Program）。新的MPRP项目在投资方式、投资金额、申请费用、申请条件、医疗保险等方面都做了调整。</p>
        <p>时隔2个多月，当地时间2021年3月26日，马耳他移民局正式公布了“Malta Permanent Residence Programme简称MPRP”项目的投资要求和细节。</p>
        <p><span style="color: #0000ff;"><strong>马耳他MPRP项目</strong></span></p>
        <p><strong>1、投资要求</strong></p>
        <p>政府捐款</p>
        <p>租房方式：捐款金额5.8万欧元<br>
          购房方式：捐款金额2.8万欧元<br>
          慈善捐款</p>
        <p>2000欧元到当地非盈利组织<br>
          房产投资（持有五年时间）</p>
        <p>购房金额：马耳他主岛35万欧元以上，戈佐岛/马耳他南部30万欧元以上<br>
          租房金额：马耳他主岛最低1.2万欧元以上/年，戈佐岛/马耳他南部最低1万欧元以上/年。</p>
        <p>旧政：申请人可以投资25万欧元于马耳他的国债、股票、公司债券等金融产品，也可以选择融资方式，由金融机构贷款购买国债。</p>
        <p>相比旧政，从投资标的来看，投资方式更为简便，取消了原有的国债投资，省去了投资人购买国债，基金公司开户的烦恼；其次，从投资金额来看，整体投资额度低至7万欧元（包含5.8万欧元的捐款，2000欧元的慈善捐款和1万欧元一年的房租），汇款难度更小，投资更加便利。</p>
        <p><strong>2.资产证明</strong></p>
        <p>资产证明</p>
        <p>主申请人需要证明拥有50万欧元的净资产<br>
          其中15万欧元为存款或其他容易变现的金融资产</p>
        <p>相比旧政中主申请人需要证明拥有50万欧元的净资产，或者10万欧元的年收入；实践中还需要投资人提供25万欧元的流动资产。新政对于投资人的资产证明要求进一步降低，能够让更多的申请人满足项目的要求，从而实现赴马耳他生活、定居的目的。</p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-22862" src="@/assets/news/3/2.png" alt="" width="830" height="250" sizes="(max-width: 830px) 100vw, 830px"></p>
        <p><strong>3.申请流程</strong></p>
        <p>签发原则性获批函后的2个月内，主申请人需要支付尾笔行政管理费3万欧元（不可退）。</p>
        <p>在签发原则性获批函后的8个月内，主申请人需要完成如下投资要求：</p>
        <p>◆捐款2.8万或者5.8万欧元；<br>
          ◆提供符合条件的房产投资证明；<br>
          ◆完成2000欧元慈善捐款并向身份局提供捐款证明；<br>
          ◆购买通常需要覆盖马耳他的综合保险；<br>
          ◆提供法案要求的其他文件；</p>
        <p><img loading="lazy" class="aligncenter wp-image-22861" src="@/assets/news/3/5.png" alt="" width="645" height="166"  sizes="(max-width: 645px) 100vw, 645px"> <img loading="lazy" class="aligncenter wp-image-22860" src="@/assets/news/3/3.png" alt="" width="646" height="304"  sizes="(max-width: 646px) 100vw, 646px"></p>
        <p>相比旧政，付款时间节点更加清晰明了，投资者一目了然，可以更好的提前安排投资款项。</p>
        <p><strong>4.身份属性</strong></p>
        <p>法案中明确提到马耳他所签发的身份纸可以让申请人及其附属申请人无限期地在马耳他居住、定居及生活。也进一步明确了马耳他身份的永居属性。</p>
        <p><img loading="lazy" class="aligncenter wp-image-22863" src="@/assets/news/3/4.jpg" alt="" width="658" height="439"  sizes="(max-width: 658px) 100vw, 658px"></p>
        <p>综上所述，不难看出，马耳他新政的办理成本低于之前的旧政，其次，取消国债投资要求，投资人一方面不需要担心国债收益和持有期限问题，另一方面可以减少国债开户等相关操作流程，从而缩短项目的办理周期。马耳他新政从当地时间2021年3月29日开始执行，也就是说即刻起，投资人可以以更低的成本，更快的速度登陆马耳他。</p>
        <p><strong>马耳他MPRP项目投资优势</strong></p>
        <p>全家族：一份投资，全家移民<br>
          拿永居：直接获得永久居留卡<br>
          低门槛：无移民监、学历及语言要求<br>
          四位一体：欧盟成员国、申根国、欧元国、英联邦国家<br>
          优环境：官方语言英语，社会治安良好<br>
          畅全球：护照免签英、美、加等180多个国家<br>
          低税率：无全球征税，无遗产税，企业税低至5%</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news1",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:3,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>